import React, { useState } from 'react';
import styled from 'styled-components';

import { blue, blue80, white } from 'styles/colors';
import { Container, H2, Image, Regular, Svg, TextWrapper } from 'components';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import { IngredientPopup } from '../elements/IngredientPopup';

const CheckIcon: React.FC = React.memo(() => (
  <Svg
    src="icons/checkmark_blue"
    viewBox="0 0 18 18"
    height="18"
    width="18"
    style={{ marginRight: '4px' }}
  />
));
CheckIcon.displayName = 'CrossIcon';

const checkList = [
  'High blood pressure',
  'High cholesterol',
  'High blood sugar',
  'Weight management',
];

export const OneBox: React.FC = React.memo(() => {
  const { isMobile } = useQuery();
  const [popup, setPopup] = useState<string>();

  const handlePopup = (id: string) => {
    if (popup?.length) return;
    setPopup(id);
  };

  return (
    <Container padding="0" mobilePadding="2rem 1rem 0">
      <IngredientPopup popup={popup} setPopup={setPopup} />
      <StyledContainer padding="0">
        <BoxImage
          src={isMobile ? 'pulsio_box_opened_mobile' : 'pulsio_box_opened'}
          padding={isMobile ? '0 0 3rem 2rem' : '0 0 0 3rem'}
        />
        <RightColumn>
          <TextWrapper maxWidth="34.75rem">
            <H2 color={blue}>One box is all your heart needs</H2>
            <CheckList>
              {checkList.map(li => (
                <CheckListItem key={li}>
                  <CheckIcon />
                  <CheckListText color={blue80}>{li}</CheckListText>
                </CheckListItem>
              ))}
            </CheckList>
            <IngreedientWrapper>
              <Fact color={blue} onClick={() => handlePopup('product_packets')}>
                See ingredient facts
              </Fact>
            </IngreedientWrapper>
          </TextWrapper>
        </RightColumn>
        {!isMobile && <Curve src="curve" width="80.75rem" />}
      </StyledContainer>
    </Container>
  );
});

OneBox.displayName = 'OneBox';

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  margin-top: 0;
  position: relative;
  transform: translateY(15%);
  border-radius: 10px;
  background: ${white};
  border: 1px solid ${blue};

  @media ${tablet} {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 3.75rem;
    margin-top: 1.75rem;
    padding-bottom: 2.5rem;
    transform: none;
  }
`;
const BoxImage = styled(Image)`
  max-width: 28rem;
  width: 100%;
  @media ${mobile} {
    max-width: 100%;
  }
`;
const RightColumn = styled.div`
  padding-left: 8.5%;
  margin-top: 2.5rem;

  @media ${tablet} {
    padding-left: 0;
    margin-top: -3.25rem;
  }
`;
const IngreedientWrapper = styled.div`
  display: inline-block;
  padding-bottom: 3.25rem;

  @media ${mobile} {
    padding-bottom: 0;
  }
`;
const CheckList = styled.div`
  margin: 2rem 0 2rem 0;
  display: flex;
  align-items: flex-start;
  grid-gap: 0.5rem;
  flex-direction: column;
`;
const CheckListItem = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;
const CheckListText = styled(Regular)`
  min-width: 10rem;
  margin-left: 0.25rem;
`;
const Fact = styled(Regular)`
  text-decoration: underline;
  cursor: pointer;
  z-index: 2;
`;
const Curve = styled(Image)`
  position: absolute;
  bottom: 0;
  left: -76px;
  z-index: -1;
`;
