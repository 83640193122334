import { FlexWrapper, Regular, Small, Svg } from 'components';
import React, { MouseEvent, useEffect, useRef } from 'react';

import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';
import { black, text } from 'styles/colors';

interface Props {
  setPopup: (value?: string) => void;
  popup?: string;
}

interface IIngredient {
  ingredient: string;
  amount: string;
}
interface IDescription {
  servingSize: string;
  servings?: string;
  ingredients: Array<IIngredient>;
  total: string;
}
type Descriptions = {
  [key: string]: IDescription;
};

const DESCRIPTIONS: Descriptions = {
  product_packets: {
    servingSize: 'Serving size: 1 sachet',
    servings: 'Servings per container 30',
    ingredients: [
      {
        ingredient: 'Vitamin K2',
        amount: '100 mg',
      },
      {
        ingredient: 'Cinnamon bark extract',
        amount: '25 mg',
      },
      {
        ingredient: 'Fenugreek seed',
        amount: '75 mg',
      },
      {
        ingredient: 'Nattokinase',
        amount: '50 mg',
      },
      {
        ingredient: 'Pine bark extract',
        amount: '50 mg',
      },
      {
        ingredient: 'Policosanol (50% Octacosanol)',
        amount: '12 mg',
      },
      {
        ingredient: 'Odorless garlic (Allium sativum)',
        amount: '200 mg',
      },
      {
        ingredient: 'Coenzyme Q10',
        amount: '100 mg',
      },
      {
        ingredient: 'Grape seed extract',
        amount: '100 mg',
      },
      {
        ingredient: 'Dietary fiber',
        amount: '105 mg',
      },
      {
        ingredient: 'Guggul gum',
        amount: '105 mg',
      },
      {
        ingredient: 'Ginger extract',
        amount: '20 mg',
      },
      {
        ingredient: 'Beetroot extract',
        amount: '20 mg',
      },
    ],
    total: '962 mg',
  },
};

export const IngredientPopup: React.FC<Props> = React.memo(
  ({ popup, setPopup }) => {
    const ref = useRef<HTMLElement>(null);

    useEffect(() => {
      const handleClick = (event: React.MouseEvent<Element, MouseEvent>) => {
        if (ref.current && !ref.current?.contains(event.target as Node)) {
          setPopup(undefined);
        }
      };
      document.addEventListener('mousedown', handleClick);

      return () => {
        document.removeEventListener('mousedown', handleClick);
      };
    }, [ref]);

    if (!popup?.length) return null;
    return (
      <PopupBg>
        <Popup ref={ref}>
          <ClosePopup
            src="icons/close_popup"
            width="52"
            height="36"
            viewBox="0 0 52 36"
            fill="none"
            onClick={() => setPopup(undefined)}
          />
          <PopupTitle>Supplement facts</PopupTitle>
          <Regular margin="0 0 1rem">{DESCRIPTIONS[popup].servingSize}</Regular>
          {DESCRIPTIONS[popup].servings ? (
            <Regular margin="0 0 1rem">{DESCRIPTIONS[popup].servings}</Regular>
          ) : null}
          <Hr />
          <TableHead>
            <Small fontWeight="bold">ACTIVE INGREDIENTS</Small>
            <Small textAlign="right" fontWeight="bold">
              AMOUNT PER SERVING
            </Small>
          </TableHead>

          {DESCRIPTIONS[popup].ingredients.map((ingr, ingrIdx) => (
            <>
              <TableRow key={ingrIdx}>
                <Regular>{ingr.ingredient}</Regular>
                <Regular textAlign="right">{ingr.amount}</Regular>
              </TableRow>
              {ingrIdx !== DESCRIPTIONS[popup].ingredients.length - 1 && (
                <HrLight />
              )}
            </>
          ))}
        </Popup>
      </PopupBg>
    );
  },
);

IngredientPopup.displayName = 'IngredientPopup';

const PopupBg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 50rem;
  padding: 2.5rem 5rem 4rem;
  box-sizing: border-box;
  background: #f6f6f6;
  z-index: 999;
  max-height: 90vh;
  overflow: auto;

  @media ${tablet} {
    min-width: 85vw;
    padding: 2rem;
    top: unset;
    left: unset;
    transform: unset;
  }
`;

const PopupTitle = styled.h6`
  font-family: 'ivymode', sans-serif;
  font-weight: normal;
  font-size: 2rem;
  margin: 1rem 0;
  padding: 0;
  color: ${text};
`;

const ClosePopup = styled(Svg)`
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  cursor: pointer;

  @media ${tablet} {
    right: 1rem;
    top: 1rem;
  }
`;

const Hr = styled.div`
  width: 100%;
  height: 2px;
  background: ${black};
`;

const TableHead = styled(FlexWrapper)`
  justify-content: space-between;
  margin: 0.8rem 0;
  p {
    color: ${black};
    text-transform: uppercase;
    font-size: 0.8125rem;
    font-weight: bold;
    &:nth-child(2) {
      text-align: right;
    }
  }
`;

const TableRow = styled.section<{ first?: boolean }>`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  padding: 0.8rem 0;
  width: 100%;
  > &:nth-child(2) {
    text-align: right;
  }

  &:first-of-type {
    border-top: 1px solid #c4c4c4;
  }
`;

const HrLight = styled(Hr)`
  height: 0.1px;
  background: #c4c4c4;
`;
