import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Element, scroller } from 'react-scroll';

import { images } from 'utils/images';
import { almostWhite } from 'styles/colors';
import {
  Container,
  FaqList,
  Featured,
  FlexWrapper,
  H1,
  H2,
  Image,
  PrivateRoute,
  Seo,
  Testimonials,
} from 'components';

import { Hero } from './sections/Hero';
import { ProductSelection } from './sections/ProductSelection';
import { ProductSelectionBottom } from './sections/ProductSelectionBottom';
import { OneBox } from './sections/OneBox';
import { PlanBuiltForYou } from './sections/PlanBuiltForYou';
import { HowDoesItWork } from './sections/HowDoesItWork';
import { RapidRelief } from './sections/RapidRelief';
import { ComparisionTable } from './sections/ComparisionTable';
import { mobile, useQuery } from 'styles/breakpoints';
import { Analytics } from 'apis/Analytics';

const Checkout2: React.FC = () => {
  const { isMobile } = useQuery();

  const handleCtaClick = useCallback(() => {
    Analytics.trackCTAButton('checkout');
    scroller.scrollTo('products', {
      spy: true,
      smooth: true,
      duration: 500,
    });
  }, []);

  return (
    <>
      <Seo title="Personalized support for cardiovascular system | Pulsio" />
      <PrivateRoute>
        <Background>
          <Hero />
          <Element name="products">
            <ProductSelection />
          </Element>
          <ComparisionTable onClick={handleCtaClick} />
          <OneBox />
        </Background>
        <PlanBuiltForYou />
        <FeaturedWrapper>
          <Featured ctaTitle="GET TREATMENT" onClick={handleCtaClick} />
        </FeaturedWrapper>
        <HowDoesItWork />
        <Testimonials
          onCtaClick={handleCtaClick}
          ctaText="GET TREATMENT"
          title={
            <TestimonialTitleContainer
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              margin="0 0 4.375rem"
            >
              <FlexWrapper
                alignItems="center"
                justifyContent="center"
                flexDirection={isMobile ? 'column' : 'row'}
              >
                <ImageContainer>
                  <H1 color={almostWhite}>Over 74,212</H1>
                  <Image
                    src="woman_smilling"
                    width="4.375rem"
                    margin="0 1.25rem"
                  />
                </ImageContainer>
                <H1 color={almostWhite}>cardiovascular</H1>
              </FlexWrapper>
              <H1 color={almostWhite}>systems improved with Pulsio</H1>
            </TestimonialTitleContainer>
          }
        />
        <RapidRelief onClick={handleCtaClick} />
        <FaqContainer>
          <H2 margin={isMobile ? '0 0 3.125rem 1.3rem' : '0 0 3.75rem 1.3rem'}>
            FAQ
          </H2>
          <FaqList />
        </FaqContainer>
        <ProductSelectionBottom />
      </PrivateRoute>
    </>
  );
};

export default Checkout2;

const Background = styled.div`
  padding-bottom: 36rem;
  background-image: url(${images.checkout_hero_background});
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;

  @media ${mobile} {
    padding-bottom: 65rem;
    background-position: top;
    background-size: 200% 80.5%;
  }
`;
const TestimonialTitleContainer = styled(FlexWrapper)`
  @media ${mobile} {
    text-align: center;
  }
`;
const FaqContainer = styled(Container)`
  padding-top: 7.5rem;
  padding-bottom: 10rem;
  max-width: 63.125rem;

  @media ${mobile} {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
`;
const FeaturedWrapper = styled.div`
  margin-top: -26rem;
  @media ${mobile} {
    margin-top: -64.5rem;
  }
`;
const ImageContainer = styled.div`
  display: flex;
  align-items: center;
`;
